import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import ConsultaProcessos from './ConsultaProcessos';
import NavBar from './NavBar';
import Servicos from "./Servicos";
import Modelos from "./Modelos";
import Equipe from "./Equipe";
import TipoProcesso from './TipoProcesso';
import {endpointEscritorio, urlHost} from '../../../../utils/Constants';
import axios from 'axios';
import Footer from './Footer';
import ProcessoEmpresa from './ProcessoEmpresa';
import EnvioDocumentos from './EnvioDocumentos';
import '../../../../resources/css/overrides-bs.css'

function SiteTemplate() {
    const [escritorios, setEscritorios] = useState([]);

    useEffect(() => {

        const loadEscritorios = () => {
            axios.get(urlHost + endpointEscritorio)
                .then(response => {
                    if (response.status === 200) {
                        setEscritorios(response.data);
                    }
                }).catch(error => {
                console.log(error);
            });
        }

        loadEscritorios();
    }, []);

    return (
        <div className='site-body'>
            <NavBar />
            <main className="site-content">
                <Routes>
                    <Route path="/" exact element={<Home />}/>
                    <Route path="/servicos" element={<Servicos/>} />
                    <Route path="/tipo-processo" element={<TipoProcesso />} />
                    <Route path="/processos/:tipo" element={<ConsultaProcessos />} />
                    <Route path="/processos/:tipo/:nomeEmpresa/:idProcesso" element={<ProcessoEmpresa />} />
                    <Route path="/envio-documento" element={<EnvioDocumentos />} />
                    <Route path="/modelos" element={<Modelos/>} />
                    <Route path="/equipe" element={<Equipe/>} />
                </Routes>
            </main>
            <Footer escritorios={escritorios}/>
        </div>
    );
}

export default SiteTemplate;