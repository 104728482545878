import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { setFooterDark } from '../../../../utils/Constants';
import imgRecuperacao from '../../../../resources/img/recuperacao_judicial.webp'
import imgFalecia from '../../../../resources/img/falencia.webp'
import imgConstatacao from '../../../../resources/img/constatacao_previa.webp'
import imgGestao from '../../../../resources/img/gestao_judicial.webp'
import imgInventario from '../../../../resources/img/inventario.webp'
import imgPenhora from '../../../../resources/img/penhora_faturamento.webp'

const Servicos = () => {
    useEffect(() => {
        setFooterDark(true);
    }, [])

    return (
        <>
            <div className="page-header servicos">
                <div className="container clr page-header-inner">
                    <h1 className="page-header-title clr">Áreas de Atuação</h1>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>
            <div className='site-section'>
                <Container>
                    <Row>
                        <h3 className="mb-5">
                            Advogados especializados e com ampla experiência em processos de falência e recuperações judiciais de
                            empresas de diferentes segmentos.
                            <br></br><br></br>Conheça nossos serviços:
                        </h3>
                    </Row>
                    <Row className="row-atuacao">
                        <Col className='tipo-card-container mb-4' sm={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgRecuperacao} height={270} width={180} alt="Recuperação Judicial"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Recuperação Judicial</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    Previsto na Legislação brasileira, a recuperação judicial tem como principal objetivo
                                                    evitar a falência de empresas que enfrentam crises econômicas e/ou financeiras.
                                                    Por meio desse processo, pode-se renegociar dívidas, reorganizar a estrutura
                                                    empresarial e implementar um plano de recuperação.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container mb-4' sm={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgFalecia} height={270} width={180} alt="Falência"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Falência</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    Uma das principais especialidades do escritório, o processo de falência oferece
                                                    todo o suporte jurídico necessário a empresas e empresários que enfrentam
                                                    a insolvência. A atuação de nossos advogados visa garantir que a falência
                                                    ocorra de forma ordenada, justa e transparente, protegendo os direitos dos envolvidos.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row-atuacao">
                        <Col className='tipo-card-container mb-4' xs={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgConstatacao} height={270} width={180} alt="Constatação Prévia"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Constatação Prévia</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    Voltada para a realização de inspeções detalhadas e de avaliações jurídicas que
                                                    antecedem os processos de recuperação judicial e de falência, a constatação prévia
                                                    fornece uma análise precisa e detalhada da situação financeira e operacional das
                                                    empresas, o que auxilia na tomada de decisões.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container mb-4' xs={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgInventario} height={270} width={180} alt="Inventário"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Inventário</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    Os processos de inventário envolvem a prestação de serviços jurídicos personalizados
                                                    no âmbito da sucessão patrimonial. Nesse caso, nosso principal objetivo é garantir
                                                    que cada ação ocorra de forma eficiente, transparente e conforme a Legislação vigente,
                                                    respeitando os direitos e os interesses de todos os herdeiros.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row-atuacao">
                        <Col className='tipo-card-container mb-4' xs={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgPenhora} height={295} width={180} alt="Penhora de Faturamento"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Penhora de Faturamento</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    A penhora de faturamento, que consiste em uma medida judicial que permite ao
                                                    credor receber parte do faturamento de uma empresa devedora como forma de pagamento
                                                    de uma dívida, é um processo eficaz para assegurar que uma dívida seja quitada sem
                                                    que a empresa devedora precise encerrar suas atividades.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container mb-4' xs={12} md={12} lg={6}>
                            <Card className="text-center tipo-card-atuacao">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={6} lg={6} xl={4} className='d-flex align-items-start justify-content-center mb-3 mb-md-0'>
                                            <img src={imgGestao} height={295} width={180} alt="Gestão Judicial"/>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={8} className='d-flex align-items-start'>
                                            <div>
                                                <h4><strong>Gestão Judicial</strong></h4>
                                                <Card.Text className="text-card-atuacao">
                                                    Dedicada a fornecer suporte jurídico integral na administração de empresas em
                                                    situação de crise, a gestão judicial, que é usada em processos de recuperação
                                                    judicial e falência, visa garantir a continuidade dos negócios, bem como proteger
                                                    os direitos dos credores e assegurar a viabilidade econômica das empresas.
                                                </Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Servicos;