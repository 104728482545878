import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import '../../../../resources/css/navbar.css'
import {ReactComponent as GeoIcon} from '../../../../resources/img/iconeLocal.svg';
import {ReactComponent as TelefoneIcon} from '../../../../resources/img/telefone.svg';
import {ReactComponent as EnvelopeIcon} from '../../../../resources/img/envelope.svg';
import {ReactComponent as LogoAgenciaKnup} from '../../../../resources/img/LogoAgencia.svg';
import {ReactComponent as WhatsAppIcon} from '../../../../resources/img/whatsapp-footer.svg';
import {ReactComponent as WhatsApp} from '../../../../resources/img/botao-whatsapp.svg';
import {ReactComponent as InstagramIcon} from '../../../../resources/img/iconeInstagram.svg';
import logo from "../../../../resources/img/logo-dias-nogueira.webp";
import logoSecundaria from "../../../../resources/img/logoFooter.webp";
import {Col, Row} from "react-bootstrap";
import {formatPhoneNumber, isValidPhoneNumber} from "../../../../utils/Utils";

const Footer = props => {

    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        const element = document.getElementById("footer");

        if(element){
            setIsDark(element.classList.contains("dark"));

            const observer = new MutationObserver(mutationsList => {
                for (let mutation of mutationsList) {
                    if (mutation.attributeName === 'class') {
                        setIsDark(mutation.target.classList.contains("dark"));
                    }
                }
            });

            observer.observe(element, { attributes: true });
            return () => observer.disconnect();
        }
    }, []);

    const anoAtual = new Date().getFullYear();

    return (<div id='footer' className="custom-footer dark">
        <Container>
            <Row>
                <Col sm={6} className="mt-5">
                    <a href="/" style={{textDecoration: 'none'}}>
                        <div>
                            <figure>
                                <img src={isDark ? logo : logoSecundaria} height={100} alt="Logo Dias & Nogueira" title="Logo Dias & Nogueira"/>
                                <figcaption>
                                    <br></br>DIAS & NOGUEIRA – ASSESSORIA, CONSULTORIA E SERVIÇOS EMPRESARIAIS LTDA.
                                    <br></br> CNPJ: 50.363.572/0001-03
                                </figcaption>
                            </figure>
                        </div>
                    </a>
                    <div className="social-networks">
                        <InstagramIcon/>
                        <a className="ms-2" href="https://www.instagram.com/diasenogueira" target="_blank" rel="noopener noreferrer" >Instagram</a>
                    </div>
                </Col>
                <Col sm={6} className="mt-4">
                    <div>
                        <h3>CONTATOS</h3>
                        {
                            props.escritorios.map((escritorio, index) => (
                                <div key={index}>
                                    <div className="footer-section">
                                        {isValidPhoneNumber(escritorio.telefone) && (
                                            <div id="foneEmpresa" className="mb-1">
                                                <TelefoneIcon/>
                                                <span className="ms-2">{formatPhoneNumber(escritorio.telefone)}</span>
                                            </div>
                                        )}
                                        {isValidPhoneNumber(escritorio.celular) && (
                                            <div id="whatsappEmpresa" className="mb-1">
                                                <a href={`https://wa.me/${encodeURIComponent(`${escritorio.celular}`)}`}
                                                   target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                                                    <WhatsAppIcon/>
                                                    <span className="ms-2">{formatPhoneNumber(escritorio.celular)}</span>
                                                </a>
                                            </div>
                                        )}
                                        {isValidPhoneNumber(escritorio.celular) && (
                                            <div className="whatsapp-button">
                                                <a href={`https://wa.me/${encodeURIComponent(`${escritorio.celular}`)}`} target="_blank" rel="noopener noreferrer">
                                                    <WhatsApp/>
                                                </a>
                                            </div>
                                        )}
                                        <div id="emailEmpresa" className="mb-1">
                                            <a href={`mailto:${escritorio.email}`} style={{textDecoration: 'none', color: 'inherit'}}>
                                                <EnvelopeIcon/>
                                                <span className="ms-2">{escritorio.email}</span>
                                            </a>
                                        </div>
                                        {escritorio.endereco.map((endereco, i) => (
                                            <div key={i} className="footer-section">
                                                <div id="enderecoEmpresa" className="mb-1">
                                                    <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${endereco.logradouro}, ${endereco.numero}, ${endereco.bairro} - ${endereco.cidade} - ${endereco.uf}`)}`}
                                                       target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                                                        <GeoIcon/>
                                                        <span className="ms-2"> {endereco.logradouro}, {endereco.numero}, {endereco.bairro} - {endereco.cidade} - {endereco.uf}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </Col>
            </Row>
            <div style={{textAlign: 'center', marginTop: '1em'}}>
                <a href="https://wa.me/message/UD47HU6U7N3LF1" target="_blank" rel="noopener noreferrer" style={{fontSize: '14px'}}>
                    Desenvolvido por <LogoAgenciaKnup className="logoStyle"/></a>
            </div>
            <Row>
                <div style={{textAlign: 'center', marginTop: '1em'}}>
                    <span>&copy; Copyright 2023 - {anoAtual}. Todos os direitos reservados.</span>
                </div>
            </Row>
        </Container>
    </div>)
};

export default Footer;