import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingUser, faSackDollar,faLandmark } from '@fortawesome/free-solid-svg-icons';
import { setFooterDark } from '../../../../utils/Constants';

const TipoProcesso = () => {
    useEffect(() => {
        setFooterDark(true);
    }, [])

    return (
        <>
            <div className="page-header tipo-processo">	
                <div className="container clr page-header-inner">
                    <h1 className="page-header-title clr">Tipo de Processo</h1>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>
            <div className='site-section'>
                <Container className="d-flex align-items-center justify-content-center">
                    <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 g-4" style={{padding: '50px 10px'}}>
                        <Col className='tipo-card-container'>
                            <Card className="text-center tipo-card w-100">
                                <LinkContainer to='/processos/recuperacao-judicial'>
                                    <Card.Body className="pt-5">
                                        <FontAwesomeIcon className='tipo-icon' icon={faBuildingUser} />
                                        <Card.Text>Recuperação Judicial</Card.Text>
                                    </Card.Body>
                                </LinkContainer>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container'>
                            <Card className="text-center tipo-card w-100">
                                <LinkContainer to='/processos/falencia'>
                                    <Card.Body className="pt-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="64" height="64" fill="currentColor">
                                            <path d="M21.207,13.781,15.5,8.074l-2,2-5.8-5.8L6.281,5.683,13.5,12.9l2-2L19.793,15.2,17,17.988h5.5a1.5,1.5,0,0,0,1.5-1.5v-5.5Z" fill="currenredtColor"/>
                                            <path d="M2,20.976V0H0V20.976a3,3,0,0,0,3,3H24v-2H3A1,1,0,0,1,2,20.976Z"/>
                                        </svg>
                                        <Card.Text>Falência</Card.Text>
                                    </Card.Body>
                                </LinkContainer>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container'>
                            <Card className="text-center tipo-card w-100">
                                <LinkContainer to='/processos/penhora-de-faturamento'>
                                    <Card.Body className="pt-5">
                                        <FontAwesomeIcon className='tipo-icon' icon={faSackDollar} />
                                        <Card.Text>Penhora de Faturamento</Card.Text>
                                    </Card.Body>
                                </LinkContainer>
                            </Card>
                        </Col>
                        <Col className='tipo-card-container'>
                            <Card className="text-center tipo-card w-100">
                                <LinkContainer to='/processos/inventario'>
                                    <Card.Body className="pt-5">
                                        <FontAwesomeIcon className='tipo-icon' icon={faLandmark} />
                                        <Card.Text>Inventário</Card.Text>
                                    </Card.Body>
                                </LinkContainer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default TipoProcesso;