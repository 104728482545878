import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {endpointModeloResumido, setFooterDark, urlHost, urlHostBase} from "../../../../utils/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {Spinner} from "react-bootstrap";

function Modelos() {

    const [modelos, setModelos] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadModelos = useCallback(() => {
        axios.get(urlHost + endpointModeloResumido)
            .then(response => {
                if (response.status === 200) {
                    setModelos(response.data);
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error);
        });
    }, []);

    useEffect(() => {
        setFooterDark(false);
        loadModelos();
    }, [loadModelos]);


    return (
        <>
            <div className="page-header modelos">
                <span className="background-image-page-header-overlay"></span>
            </div>
            <div id='modelos' className="site-section dark fill-height-rows pattern-left">
                <Container>
                    <div>
                        <Row className="align-items-center">
                            <Col className="text-align-center">
                                <div className="site-section-title-wrapper">
                                    <div className="site-section-title stroked-shadow"><h1 className="site-section-title stroked-shadow">Modelos de Documentos</h1>
                                    </div>
                                    <span className="title-stroked">Modelos</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {loading ? (
                            <Row className="justify-content-md-center">
                                <Col md={12} style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" role="status"> {} </Spinner>
                                </Col>
                            </Row>
                        ) : modelos.length > 0 ? (
                            <div className="home-card-container align-items-center">
                                {modelos.map((modelo) => (
                                    <a href={urlHostBase + '/' + modelo.path} target="_blank" rel="noreferrer">
                                        <div className="home-card text-align-center">
                                            <FontAwesomeIcon icon={faFileLines} className="home-card-icon"/>
                                            <span className="home-card-text">{modelo.nome}</span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        ):(
                            <div style={{textAlign: 'center'}}>Nenhum modelo encontrado.</div>
                        )}
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Modelos;