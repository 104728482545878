import React, { useState } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { endpointArquivoLogoEmpresa, endpointEmpresa, extPermitidasLogoEmpresa, nomeJWTUsuario, urlHost, urlHostBase } from "../../../../utils/Constants";
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { setMsgErro, setMsgInfo } from '../../../../utils/Utils';

function Empresas() {
    const [cookies] = useCookies(nomeJWTUsuario);
    const [empresas, setEmpresas] = useState([]);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmpresa, setSelectedEmpresa] = useState({
        id: '',
        nome: '',
        resumo: '',
        logo: {}
    });
    const [msgAlert, setMsgAlert] = useState('');
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    const [arquivoLogo, setArquivoLogo] = useState();
    const [logoPreview, setLogoPreview] = useState();
    const [deleteLogo, setDeleteLogo] = useState(false);

    const handleAddEmpresa = () => {
        setMsgAlert('');
        setShowModal(true);
        setSelectedEmpresa({
            id: '',
            nome: '',
            resumo: '',
            logo: {}
        });
        setArquivoLogo(null);
        setLogoPreview(null);
        setDeleteLogo(false);
    };

    const handleEditEmpresa = (empresa) => {
        setMsgAlert('');
        setShowModal(true);
        setSelectedEmpresa(empresa);
        setArquivoLogo(null);
        setLogoPreview(empresa.logo ? urlHostBase+'/'+empresa.logo.linkArquivo : null);
        setDeleteLogo(false);
    };

    const handleSaveEmpresa = (logo) => {
        if(logo) {
            selectedEmpresa.logo = logo;
        }

        if (selectedEmpresa.id) {
            axios.put(urlHost + endpointEmpresa, selectedEmpresa, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleEmpresaResponse(response);
                })
                .catch(error => {
                    handleEmpresaErrorResponse(error);
                });
        } else {
            axios.post(urlHost + endpointEmpresa, selectedEmpresa, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleEmpresaResponse(response);
                })
                .catch(error => {
                    handleEmpresaErrorResponse(error);
                });
        }
        setShowModal(false);
    };

    const handleEmpresaResponse = (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setMsgGeral({ msg: 'Empresa ' + (selectedEmpresa.id ? 'atualizada' : 'cadastrada') + ' com sucesso!', variant: 'info' });
                setShowModal(false);
                loadEmpresas();
            } else {
                setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
            }
        } else {
            setMsgAlert('Erro: ' + response.data);
        }
    }

    const handleEmpresaErrorResponse = (error) => {
        if (error.response.data) {
            setMsgAlert('Erro: ' + error.response.data);
        } else {
            setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
        }
    }

    const handleDeleteEmpresa = () => {
        setMsgGeral({ msg: '' })
        axios.delete(urlHost + endpointEmpresa + '/' + selectedEmpresa.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {
                        setMsgGeral({ msg: 'Empresa excluída com sucesso!', variant: 'info' });
                        loadEmpresas();
                    } else {
                        setMsgGeral({ msg: 'Sem resposta do servidor. Tente novamente em alguns instantes.' });
                    }
                } else {
                    setMsgGeral('Erro: ' + response.data);
                }
            })
            .catch(error => {
                setShowDeleteConfirmationModal(false);
                if (error.response.data) {
                    setMsgGeral({ msg: 'Erro: ' + error.response.data });
                } else {
                    setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
                }
            });
    };

    const showDeleteConfirmation = (empresa) => {
        setSelectedEmpresa(empresa);
        setShowDeleteConfirmationModal(true);
    };

    function loadEmpresas() {
        axios.get(urlHost + endpointEmpresa, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setEmpresas(response.data);
                }
            }).catch(error => {
                setMsgGeral('Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')');
            });;
    }

    const handleFileChangeLogo = (event) => {
        if (event.target.files) {
            setArquivoLogo(event.target.files[0]);
            setLogoPreview(URL.createObjectURL(event.target.files[0]));
        }
    };

    async function handleUpload(){
        let formData = new FormData();
        let headers = {'Content-Type': 'multipart/form-data'};

        if(arquivoLogo){
            formData.append('arquivo', arquivoLogo);
        }else{
            handleSaveEmpresa();
            return;
        }

        try{
            const response1 = await axios.post(urlHost + endpointArquivoLogoEmpresa, formData, getAuthConfigHeaders(cookies, headers));
            if(response1.status === 201 || response1.status === 200) {
                setSelectedEmpresa({
                    ...selectedEmpresa,
                    logo: response1.data,
                });

                handleSaveEmpresa(response1.data);
            }
        }catch(e){
            if(e.response && e.response.data){
                setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.response.data});
            }else{
                setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.message});
            }
        }
    }

    const handleSalvar = async () => {
        if (!selectedEmpresa.nome) {
            setMsgAlert('Informe um nome para a empresa!');
            return;
        }

        if (!selectedEmpresa.resumo) {
            setMsgAlert('Informe um resumo para a empresa!');
            return;
        }

        if(deleteLogo){
            await handleDeleteLogo();
        }

        await handleUpload();
    }

    const handleDeleteLogo = async () => {
        axios.delete(urlHost + endpointEmpresa + '/' + selectedEmpresa.id+'/logo', getAuthConfigHeaders(cookies))
          .then(response => {
              setShowDeleteConfirmationModal(false);
              if (response.status === 200 || response.status === 201) {
                  if (!response.data) {
                      setMsgErro('Sem resposta do servidor. Tente novamente em alguns instantes.', setMsgGeral);
                  }
              } else {
                  setMsgErro('Erro: ' + response.data, setMsgGeral);
              }
          })
          .catch(error => {
              setShowDeleteConfirmationModal(false);
              if (error.response.data.length > 0) {
                  setMsgErro('Erro: ' + error.response.data, setMsgGeral);
              } else {
                  setMsgErro('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.', setMsgGeral);
              }
          });
    };

    const checkDeleteLogoEmpresa = () => {
        setDeleteLogo(true);
        setArquivoLogo(null);
        setLogoPreview(null);
        setDeleteLogo(true);
    }

    useEffect(() => {
        loadEmpresas();
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    {msgGeral.msg.length > 0 ?
                        <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                            {msgGeral.msg}
                        </Alert>
                        : null
                    }
                </Col>
            </Row>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card admin-card">
                        <div className="card-header d-flex justify-content-between align-items-center">Empresas <Button variant="primary" onClick={handleAddEmpresa}>
                            Adicionar Empresa
                        </Button></div>
                        <div className="card-body">
                            <Table hover responsive className="mt-3 table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '70px' }}>ID</th>
                                        <th>Nome</th>
                                        <th style={{ width: '100px' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {empresas.map((empresa) => (
                                        <tr key={empresa.id}>
                                            <td>{empresa.id}</td>
                                            <td>{empresa.nome}</td>
                                            <td>
                                                <Button
                                                    variant="link"
                                                    onClick={() => handleEditEmpresa(empresa)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Button
                                                    variant="link"
                                                    onClick={() => showDeleteConfirmation(empresa)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered  backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Dados da Empresa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-admin'>
                        <Row>
                            <Col>
                                {msgAlert.length > 0 ?
                                    <Alert variant='danger'>
                                        {msgAlert}
                                    </Alert>
                                    : null
                                }
                            </Col>
                        </Row>
                        <Form.Group controlId="formNome" className='form-admin-group'>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedEmpresa.nome}
                                onChange={(e) =>
                                    setSelectedEmpresa({
                                        ...selectedEmpresa,
                                        nome: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="formResumo" className='form-admin-group'>
                            <Form.Label>Resumo</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={selectedEmpresa.resumo}
                                onChange={(e) =>
                                    setSelectedEmpresa({
                                        ...selectedEmpresa,
                                        resumo: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group controlId="uploadArquivos" className='form-group'>
                            <Form.Label>Logo da empresa</Form.Label>
                            <div className="empresa-logo-container">
                                {logoPreview ? <img src={logoPreview} alt='Logo da Empresa' /> : <span></span>}
                                {logoPreview ? 
                                <Button
                                    className='empresa-logo-delete-button'
                                    variant="link"
                                    onClick={checkDeleteLogoEmpresa}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                                : null}
                            </div>
                            <Form.Control type="file" multiple accept={extPermitidasLogoEmpresa} onChange={handleFileChangeLogo}/>
                            {!arquivoLogo ? 
                                <Form.Text className="text-muted">
                                </Form.Text>    
                                : null
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => {handleSalvar();}}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered  backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir a empresa <b>{selectedEmpresa.nome}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteEmpresa()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Empresas;