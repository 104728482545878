import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Alert, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
    endpointProcessoResumidoTipo,
    setFooterDark,
    urlHost,
    urlHostBase
} from '../../../../utils/Constants';
import axios from 'axios';
import { LinkContainer } from 'react-router-bootstrap';
import InputMask from 'react-input-mask';
import placeholderEmpresa from '../../../../resources/img/company-placeholder.webp';

const ConsultaProcessos = () => {
    const [processos, setProcessos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    let { tipo } = useParams();

    const tiposParaTitulos = {
        'recuperacao-judicial': 'Recuperação Judicial',
        'falencia': 'Falência',
        'penhora-de-faturamento': 'Penhora de Faturamento',
        'inventario': 'Inventário'
    };

    const titulo = tiposParaTitulos[tipo];

    function formatNomeEmpresa(nomeEmpresa){
        return nomeEmpresa.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
    }

    useEffect(() => {
        setFooterDark(true);

        const loadProcessos = () => {
            axios.get(urlHost + endpointProcessoResumidoTipo + '/' + tipo)
                .then(response => {
                    if (response.status === 200) {
                        setProcessos(response.data);
                        setLoading(false);
                    }
                }).catch(error => {
                setMsgGeral({msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')'});
            });
        }

        loadProcessos();

    }, [tipo]);

    return (
        <>
            <div className="page-header consulta-processos">	
                <div className="container clr page-header-inner">
                    <h1 className="page-header-title clr">{titulo}</h1>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>
        
            <Container className='container-processos'>
                <Row>
                    <Col>
                        {msgGeral.msg.length > 0 ?
                            <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                                {msgGeral.msg}
                            </Alert>
                            : null
                        }
                    </Col>
                </Row>
                {loading ? (
                    <Row className="justify-content-md-center">
                        <Col md={12} style={{ textAlign: 'center' }}>
                            <Spinner animation="border" role="status"> {} </Spinner>
                        </Col>
                    </Row>
                ) : processos && processos.length > 0 ? (
                <Row  className="justify-content-md-center">
                    {processos.map((processo) => (
                        <Col key={processo.idProcesso} md={12} className='col-card-processo'>
                            <Card className="mb-4 card-processo">
                                <Card.Body>
                                    <Row>
                                        <Col className='card-processo-img-container'>
                                            {processo.pathLogoEmpresa ?
                                                <img src={urlHostBase+'/'+processo.pathLogoEmpresa} alt="Logo Empresa" className='card-processo-img'/>
                                                :
                                                <img src={placeholderEmpresa} alt="Logo Empresa" className='card-processo-img'/>
                                            }
                                        </Col>
                                        <Col>
                                            <Card.Title>{processo.nomeEmpresa}</Card.Title>
                                            <Card.Text>
                                                <Row>
                                                    <Col>
                                                        <strong className='card-processo-label'>Processo: </strong>
                                                        <InputMask
                                                            mask="9999999-99.9999.9.99.9999"
                                                            maskChar={null}
                                                            value={processo.numProcesso}
                                                            readOnly
                                                            style={{ border: 'none', background: 'none', minWidth: '400px'}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='card-processo-label'>Comarca:</strong> {processo.comarca}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='card-processo-label'>Data do Pedido:</strong> {processo.resumoEmpresa}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='card-processo-label'>Fase atual:</strong> {processo.faseAtual}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{maxWidth: '200px'}}>
                                                        
                                                    </Col>
                                                    <Col>
                                                        <LinkContainer to={'/processos/'+tipo+'/'+formatNomeEmpresa(processo.nomeEmpresa)+'/'+processo.idProcesso}>
                                                            <Button className='saiba-mais' variant='primary'>Saiba mais</Button>
                                                        </LinkContainer>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                    ):(
                <Row  className="justify-content-md-center text-align-center">
                    <Col md={12} style={{width: '74.233%'}}>
                        <p>Nenhum processo encontrado.</p>
                    </Col>
                </Row>
                )}
            </Container>
        </>
    );
};

export default ConsultaProcessos;