import React, { useState } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { endpointArquivo, endpointModelo, nomeJWTUsuario, repModelos, urlHost, urlHostBase } from "../../../../utils/Constants";
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formataNomeArquivo } from '../../../../utils/Utils';

function Modelos() {
  const [modelos, setModelos] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(nomeJWTUsuario);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedModelo, setSelectedModelo] = useState({
    id: '',
    nome: '',
    linkArquivo: null,
  });
  const [file, setFile] = useState();
  const [msgAlert, setMsgAlert] = useState('');
  const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });

  const handleFileChange = (event) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleAddModelo = () => {
    setMsgGeral({ msg: '' });
    setMsgAlert('');
    setShowModal(true);
    setSelectedModelo({
      id: '',
      nome: '',
      linkArquivo: null,
    });
  };

  const handleSaveModelo = async () => {
    if (!file) {
      setMsgAlert('Selecione um arquivo!');
      return;
    }

    const formData = new FormData();
    formData.append('arquivo', file);
    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    axios.post(urlHost + endpointModelo, formData, getAuthConfigHeaders(cookies, headers))
      .then(response => {
        handleModelosResponse(response);
      })
      .catch(error => {
        handleModelosErrorResponse(error);
      });
  };

  const handleDeleteModelo = (modelo) => {
    axios.delete(urlHost + endpointArquivo + '/' + selectedModelo.id, getAuthConfigHeaders(cookies))
      .then(response => {
        setShowDeleteConfirmationModal(false);
        if (response.status === 200 || response.status === 201) {
          if (response.data) {
            setMsgGeral({ msg: 'Modelo excluído com sucesso!', variant: 'info' });
            loadModelos();
          } else {
            setMsgGeral('Sem resposta do servidor. Tente novamente em alguns instantes.');
          }
        } else {
          setMsgGeral({ msg: 'Erro: ' + response.data });
        }
      })
      .catch(error => {
        setShowDeleteConfirmationModal(false);
        if (error.response.data.length > 0) {
          setMsgGeral({ msg: 'Erro: ' + error.response.data });
        } else {
          setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
        }
      });
  };

  const handleModelosResponse = (response) => {
    if (response.status === 200 || response.status === 201) {
      if (response.data) {
        setMsgGeral({ msg: 'Modelo cadastrado com sucesso!', variant: 'info' });
        setShowModal(false);
        loadModelos();
        setFile(null);
      } else {
        setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
      }
    } else {
      setMsgAlert('Erro: ' + response.data);
    }
  }

  const handleModelosErrorResponse = (error) => {
    if (error.response.data) {
      setMsgAlert('Erro: ' + error.response.data.message);
    } else {
      setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
    }
  }


  function loadModelos() {
    axios.get(urlHost + endpointModelo, getAuthConfigHeaders(cookies))
      .then(response => {
        if (response.status === 200) {
          setModelos(response.data);
        }
      }).catch(error => {
        setMsgGeral({ msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')' });
      });;
  }

  const showDeleteConfirmation = (modelo) => {
    setSelectedModelo(modelo);
    setShowDeleteConfirmationModal(true);
  };

  useEffect(() => {
    loadModelos();
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          {msgGeral.msg.length > 0 ?
            <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
              {msgGeral.msg}
            </Alert>
            : null
          }
        </Col>
      </Row>

      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card admin-card">
            <div className="card-header d-flex justify-content-between align-items-center">
              Modelos
              <Button variant="primary" onClick={handleAddModelo}>
                Adicionar Modelo
              </Button>
            </div>
            <div className="card-body">
              <Table hover responsive className="mt-3 table">
                <thead>
                  <tr>
                    <th style={{ width: '70px' }}>ID</th>
                    <th>Nome</th>
                    <th style={{ width: '100px' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {modelos.map((modelo) => (
                    <tr key={modelo.id}>
                      <td>{modelo.id}</td>
                      <td>{formataNomeArquivo(modelo.nome)}</td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => showDeleteConfirmation(modelo)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <a href={urlHostBase+'/'+modelo.linkArquivo} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Modelo de Documento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                {msgAlert.length > 0 ?
                  <Alert variant='danger'>
                    {msgAlert}
                  </Alert>
                  : null
                }
              </Col>
            </Row>
            <Form.Group controlId="formLinkArquivo">
              <Form.Label>Arquivo</Form.Label>
              <Form.Control
                type="file"
                accept=".doc, .docx, .pdf"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveModelo}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Excluir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir o modelo <b>{selectedModelo.nome}</b>?</p>
          <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
          <Button variant="danger" onClick={() => handleDeleteModelo()}>Sim</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Modelos;