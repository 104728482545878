import { nomeJWTUsuario } from "./Constants";

export default function getAuthConfigHeaders(cookies, headers) {
    let config = {
        headers: {
            ...headers,
            Authorization: cookies[nomeJWTUsuario],
        }
    };
    return config;
}