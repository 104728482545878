import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import imgQuemSomos from '../../../../resources/img/dn-quem-somos.jpg';
import {setFooterDark} from "../../../../utils/Constants";
import { useLocation } from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";

function Home(props) {

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        setFooterDark(false);
        if (hash === '') {
            window.scrollTo(0, 0);
        }else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    return(
        <div id='home'>
            <div className="overlay-text-image-container">
                <div className="centered" >
                    <span className="overlay-text">
                        ESCRITÓRIO ESPECIALIZADO EM CONTRIBUIR ATIVAMENTE NO AUXÍLIO
                        JUDICIAL DE PROCESSOS SUCESSÓRIOS E DE INSOLVÊNCIA EMPRESARIAL
                    </span>
                </div>
                <br/>
                <div className="bottom-centered arrasta-cima">
                    Arraste para cima
                    <br/>
                    <span className="fa fa-solid fa-angle-down"></span>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>

            <div id='quem-somos' className="site-section dark fill-height-rows pattern-right text-align-center">
                <Container>
                    <Row className="justify-content-center text-center">
                        <div className="site-section-title mb-5">Quem Somos</div>

                        <img className="mt-2 mb-5 img-responsive" src={imgQuemSomos} style={{height: 'auto', border: 'none'}} alt='Imagem do Quem Somos'/>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <p className="site-section-text mt-4" style={{textAlign: 'justify'}}>
                            A DIAS & NOGUEIRA é uma empresa criada para atuação especializada em Recuperações
                            Judiciais e Falências em todo território nacional.

                            <br></br>Formada por uma equipe de profissionais altamente capacitados e com
                            especialidades singulares em diversas áreas (Direito, Administração, Ciências
                            Contábeis, etc.), oferecemos a resolução célere e criativa das demandas judiciais relacionadas
                            a insolvência para além daquelas instituídas na Lei 11.101/2005.

                            <br></br><br></br>A experiência de nossos profissionais em assessorias de magistrados
                            nos permitiu desenvolver um método de trabalho que auxilia de forma objetiva tanto a
                            assessoria quanto o magistrado condutor do processo, eliminando o desnecessário e
                            adicionando o que é essencial em nossos pareceres e relatórios, sempre embasados,
                            objetivos e claros, auxiliando o Judiciário na tomada de decisões seguras.

                            <br></br><br></br>Além disso, nossa atuação pautada na ética e no respeito às normas
                            legais fortalece a credibilidade da equipe de administradores judiciais perante os
                            credores, os colaboradores e os demais envolvidos no processo de insolvência.
                        </p>
                    </Row>
                    <div className="text-align-center">
                        <LinkContainer to={'/equipe'}>
                            <Button className='equipe btn-sm mt-3 text-align-center' variant='primary'>Saiba mais</Button>
                        </LinkContainer>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Home;