import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Container, Table, Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import {setMsgErro, formatPhoneNumber, removeNonNumeric} from '../../../../utils/Utils';
import { endpointArquivoFotoAdvogados, endpointAdvogados, extPermitidasFotoAdvogado, nomeJWTUsuario, urlHost} from "../../../../utils/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import '../../../../resources/css/admin/AlertFade.css';

function Equipe() {
    const [cookies] = useCookies(nomeJWTUsuario);
    const [advogados, setAdvogados] = useState([]);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedAdvogado, setSelectedAdvogado] = useState({
        id: '',
        nome: '',
        descricao: '',
        foto: {}
    });
    const [msgAlert, setMsgAlert] = useState('');
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    const [showAlert, setShowAlert] = useState(false);
    const [arquivoFoto, setArquivoFoto] = useState();
    const [deleteFoto, setDeleteFoto] = useState(false);

    const handleAddAdvogado = () => {
        setMsgAlert('');
        setShowModal(true);
        setSelectedAdvogado({
            id_advogado: 0,
            nome: '',
            oab: '',
            descricao: '',
            email: '',
            celular: '',
            foto: {}
        });
        setArquivoFoto(null);
        setDeleteFoto(false);
    };

    const handleEditAdvogado = (advogado) => {
        setMsgAlert('');
        setShowModal(true);
        setSelectedAdvogado(advogado);
        setArquivoFoto(null);
        setDeleteFoto(false);
    };

    const handleSaveAdvogado = (foto) => {
        if(foto) {
            selectedAdvogado.foto = foto;
        }

        if (selectedAdvogado.id) {
            axios.post(urlHost + endpointAdvogados, selectedAdvogado, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleAdvogadoResponse(response);
                })
                .catch(error => {
                    handleAdvogadoErrorResponse(error);
                });
        } else {
            axios.put(urlHost + endpointAdvogados, selectedAdvogado, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleAdvogadoResponse(response);
                })
                .catch(error => {
                    handleAdvogadoErrorResponse(error);
                });
        }
        setShowModal(false);
    };

    const handleAdvogadoResponse = (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setMsgGeral({ msg: 'Advogado ' + (selectedAdvogado.id ? 'atualizado' : 'cadastrado') + ' com sucesso!', variant: 'info' });
                setShowModal(false);
                loadAdvogados();
            } else {
                setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
            }
        } else {
            setMsgAlert('Erro: ' + response.data);
        }
    }

    const handleAdvogadoErrorResponse = (error) => {
        if (error.response.data) {
            setMsgAlert('Erro: ' + error.response.data);
        } else {
            setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
        }
    }

    const handleDeleteAdvogado = () => {
        setMsgGeral({ msg: '' })
        axios.delete(urlHost + endpointAdvogados + '/' + selectedAdvogado.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {
                        setMsgGeral({ msg: 'Advogado excluído com sucesso!', variant: 'info' });
                        loadAdvogados();
                    } else {
                        setMsgGeral({ msg: 'Sem resposta do servidor. Tente novamente em alguns instantes.' });
                    }
                } else {
                    setMsgGeral('Erro: ' + response.data);
                }
            })
            .catch(error => {
                setShowDeleteConfirmationModal(false);
                if (error.response.data) {
                    setMsgGeral({ msg: 'Erro: ' + error.response.data });
                } else {
                    setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
                }
            });
    };

    const showDeleteConfirmation = (advogado) => {
        setSelectedAdvogado(advogado);
        setShowDeleteConfirmationModal(true);
    };

    function loadAdvogados() {
        axios.get(urlHost + endpointAdvogados, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setAdvogados(response.data);
                }
            }).catch(error => {
                setMsgGeral('Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')');
            });
    }

    const handleFileChangeFoto = (event) => {
        if (event.target.files) {
            setArquivoFoto(event.target.files[0]);
        }
    };

    async function handleUpload(){
        let formData = new FormData();
        let headers = {'Content-Type': 'multipart/form-data'};

        if(arquivoFoto){
            formData.append('arquivo', arquivoFoto);
        }else{
            handleSaveAdvogado();
            return;
        }

        try{
            const response1 = await axios.post(urlHost + endpointArquivoFotoAdvogados, formData, getAuthConfigHeaders(cookies, headers));
            if(response1.status === 201 || response1.status === 200) {
                setSelectedAdvogado({
                    ...selectedAdvogado,
                    foto: response1.data,
                });

                handleSaveAdvogado(response1.data);
            }
        }catch(e){
            if(e.response && e.response.data){
                setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.response.data});
            }else{
                setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.message});
            }
        }
    }

    const handleSalvar = async () => {
        if (!selectedAdvogado.nome) {
            setMsgAlert('Informe um nome para o advogado!');
            return;
        }

        if (!selectedAdvogado.descricao) {
            setMsgAlert('Informe uma descrição para o advogado!');
            return;
        }

        if(deleteFoto){
            await handleDeleteFoto();
        }

        await handleUpload();
    }

    const handleDeleteFoto = async () => {
        axios.delete(urlHost + endpointAdvogados + '/' + selectedAdvogado.id+'/foto', getAuthConfigHeaders(cookies))
          .then(response => {
              setShowDeleteConfirmationModal(false);
              if (response.status === 200 || response.status === 201) {
                  if (!response.data) {
                      setMsgErro('Sem resposta do servidor. Tente novamente em alguns instantes.', setMsgGeral);
                  }
              } else {
                  setMsgErro('Erro: ' + response.data, setMsgGeral);
              }
          })
          .catch(error => {
              setShowDeleteConfirmationModal(false);
              if (error.response.data.length > 0) {
                  setMsgErro('Erro: ' + error.response.data, setMsgGeral);
              } else {
                  setMsgErro('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.', setMsgGeral);
              }
          });
    };

    const checkDeleteFotoAdvogado = () => {
        setDeleteFoto(true);
        setArquivoFoto(null);
        setDeleteFoto(true);
    }

    useEffect(() => {
        loadAdvogados();
    }, []);

    useEffect(() => {
        if (msgGeral.msg.length > 0) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [msgGeral]);

    return (
        <Container>
            <Row>
                <Col>
                    <div className={`fade-alert ${showAlert ? 'fade-in' : 'fade-out'}`}>
                        <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                            {msgGeral.msg}
                        </Alert>
                    </div>
                </Col>
            </Row>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card admin-card">
                        <div className="card-header d-flex justify-content-between align-items-center">Equipe <Button variant="primary" onClick={handleAddAdvogado}>
                            Adicionar membro
                        </Button></div>
                        <div className="card-body">
                            <Table hover responsive className="mt-3 table">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th style={{ width: '100px' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {advogados.map((advogado) => (
                                        <tr key={advogado.id}>
                                            <td onClick={() => handleEditAdvogado(advogado)}>{advogado.nome}</td>
                                            <td>
                                                <Button
                                                    variant="link"
                                                    onClick={() => handleEditAdvogado(advogado)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Button
                                                    variant="link"
                                                    onClick={() => showDeleteConfirmation(advogado)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered  backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Dados da Advogado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-admin'>
                        <Row>
                            <Col>
                                {msgAlert.length > 0 ?
                                    <Alert variant='danger'>
                                        {msgAlert}
                                    </Alert>
                                    : null
                                }
                            </Col>
                        </Row>
                        <Form.Group controlId="formNome" className='form-admin-group'>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedAdvogado.nome}
                                onChange={(e) =>
                                    setSelectedAdvogado({
                                        ...selectedAdvogado,
                                        nome: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="formOAB" className='form-admin-group'>
                                    <Form.Label>OAB</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedAdvogado.oab}
                                        onChange={(e) =>
                                            setSelectedAdvogado({
                                                ...selectedAdvogado,
                                                oab: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formCelular" className='form-admin-group'>
                                    <Form.Label>Celular</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formatPhoneNumber(selectedAdvogado.celular)}
                                        onChange={(e) => {
                                            setSelectedAdvogado({
                                                ...selectedAdvogado,
                                                celular: removeNonNumeric(e.target.value),
                                            })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="formEmail" className='form-admin-group'>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedAdvogado.email}
                                onChange={(e) =>
                                    setSelectedAdvogado({
                                        ...selectedAdvogado,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescricao" className='form-admin-group'>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={selectedAdvogado.descricao}
                                onChange={(e) => {
                                    if (e.target.value.length <= 2000) {
                                        setSelectedAdvogado({
                                            ...selectedAdvogado,
                                            descricao: e.target.value,
                                        });
                                    }
                                }}
                            />
                            <Form.Text muted>
                                {selectedAdvogado.descricao.length}/2000 caracteres
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="uploadArquivos" className='form-group'>
                            <Form.Label>Foto do advogado</Form.Label>
                            <Button
                                className='advogado-foto-delete-button'
                                variant="link"
                                onClick={checkDeleteFotoAdvogado}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            <Form.Control type="file" multiple accept={extPermitidasFotoAdvogado} onChange={handleFileChangeFoto}/>
                            {!arquivoFoto ?
                                <Form.Text className="text-muted">
                                </Form.Text>    
                                : null
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => {handleSalvar();}}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered  backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir a advogado <b>{selectedAdvogado.nome}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteAdvogado()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Equipe;