import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { endpointEscritorio, estados, nomeJWTUsuario, urlHost } from "../../../../utils/Constants";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';

function Escritorios() {
    const emptyEscritorio = {
        id: '',
        telefone: '',
        celular: '',
        email: '',
        dadosEmpresa: '',
        endereco: [{
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: ''
        }],
    };
    const [escritorios, setEscritorios] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedEscritorio, setSelectedEscritorio] = useState(emptyEscritorio);
    const [cookies, setCookie, removeCookie] = useCookies(nomeJWTUsuario);
    const [msgAlert, setMsgAlert] = useState('');
    const [msgGeral, setMsgGeral] = useState('');

    const handleAddEscritorio = () => {
        setShowModal(true);
        setSelectedEscritorio(emptyEscritorio);
    };

    const handleEditEscritorio = (escritorio) => {
        if (!escritorio.endereco || !escritorio.endereco[0]) {
            escritorio.endereco = emptyEscritorio.endereco;
        }
        setShowModal(true);
        setSelectedEscritorio(escritorio);
    };

    const handleSaveEscritorio = () => {
        if (selectedEscritorio.id) {
            axios.put(urlHost + endpointEscritorio, selectedEscritorio, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleEscritorioResponse(response);
                })
                .catch(error => {
                    handleEscritorioErrorResponse(error);
                });
        } else {
            axios.post(urlHost + endpointEscritorio, selectedEscritorio, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleEscritorioResponse(response);
                })
                .catch(error => {
                    handleEscritorioErrorResponse(error);
                });
        }
        setShowModal(false);
    };

    const handleEscritorioResponse = (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setMsgGeral('Escritório cadastrado com sucesso!');
                setShowModal(false);
                loadEscritorios();
            } else {
                setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
            }
        } else {
            setMsgAlert('Erro: ' + response.data);
        }
    }

    const handleEscritorioErrorResponse = (error) => {
        if (error.response.data.length > 0) {
            setMsgAlert('Erro: ' + error.response.data);
        } else {
            setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
        }
    }

    const handleDeleteEscritorio = () => {
        axios.delete(urlHost + endpointEscritorio + '/' + selectedEscritorio.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {
                        setMsgGeral('Escritório excluído com sucesso!');
                        loadEscritorios();
                    } else {
                        setMsgGeral('Sem resposta do servidor. Tente novamente em alguns instantes.');
                    }
                } else {
                    setMsgGeral('Erro: ' + response.data);
                }
            })
            .catch(error => {
                setShowDeleteConfirmationModal(false);
                if (error.response.data.length > 0) {
                    setMsgGeral('Erro: ' + error.response.data);
                } else {
                    setMsgGeral('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
                }
            });
    };

    function loadEscritorios() {
        axios.get(urlHost + endpointEscritorio, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setEscritorios(response.data);
                }
            }).catch(error => {
                setMsgGeral('Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')');
            });;
    }

    const showDeleteConfirmation = (user) => {
        setSelectedEscritorio(user);
        setShowDeleteConfirmationModal(true);
    };

    useEffect(() => {
        loadEscritorios();
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    {msgAlert.length > 0 ?
                        <Alert variant='danger'>
                            {msgGeral}
                        </Alert>
                        : null
                    }
                </Col>
            </Row>

            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card admin-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Escritórios
                            <Button variant="primary" onClick={handleAddEscritorio}>
                                Adicionar Escritório
                            </Button>
                        </div>
                        <div className="card-body">
                            <Table hover responsive className="mt-3 table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '70px' }}>ID</th>
                                        <th>Dados da Empresa</th>
                                        <th>Telefone</th>
                                        <th>Celular</th>
                                        <th>Email</th>
                                        <th>Endereço</th>
                                        <th style={{ width: '100px' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {escritorios.map((escritorio, index) => (
                                        <tr key={index}>
                                            <td>{escritorio.id}</td>
                                            <td>{escritorio.dadosEmpresa}</td>
                                            <td>{escritorio.telefone}</td>
                                            <td>{escritorio.celular}</td>
                                            <td>{escritorio.email}</td>
                                            <td>
                                                {escritorio.endereco.map((endereco, index) => (
                                                    <span key={index}>{endereco.logradouro}, {endereco.numero}, {endereco.bairro} - {endereco.cidade}, {endereco.uf}</span>
                                                ))}
                                            </td>
                                            <td>
                                                <Button
                                                    variant="link"
                                                    onClick={() => handleEditEscritorio(escritorio)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Button
                                                    variant="link"
                                                    onClick={() => showDeleteConfirmation(escritorio)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Escritório
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-admin'>
                        <Row>
                            <Col>
                                {msgAlert.length > 0 ?
                                    <Alert variant='danger'>
                                        {msgAlert}
                                    </Alert>
                                    : null
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formDadosEmpresa" className='form-admin-group'>
                                    <Form.Label>Dados da Empresa</Form.Label>
                                    <Form.Control
                                        type="text" required
                                        value={selectedEscritorio.dadosEmpresa}
                                        onChange={(e) =>
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                dadosEmpresa: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formTelefone" className='form-admin-group'>
                                    <Form.Label>Telefone</Form.Label>
                                    <Form.Control
                                        type="text" required
                                        value={selectedEscritorio.telefone}
                                        onChange={(e) =>
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                telefone: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formCelular" className='form-admin-group'>
                                    <Form.Label>Celular</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedEscritorio.celular}
                                        onChange={(e) =>
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                celular: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="formEmail" className='form-admin-group'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={selectedEscritorio.email}
                                onChange={(e) =>
                                    setSelectedEscritorio({
                                        ...selectedEscritorio,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group controlId="formEnderecos" className='form-admin-group'>
                            <Form.Label>Endereço</Form.Label>
                            <Row>
                                <Col md={8}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Logradouro" required
                                        value={selectedEscritorio.endereco[0].logradouro}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].logradouro = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Número" required
                                        value={selectedEscritorio.endereco[0].numero}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].numero = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Complemento" required
                                        value={selectedEscritorio.endereco[0].complemento}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].complemento = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Bairro" required
                                        value={selectedEscritorio.endereco[0].bairro}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].bairro = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Cidade" required
                                        value={selectedEscritorio.endereco[0].cidade}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].cidade = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Select
                                        placeholder="UF" required
                                        value={selectedEscritorio.endereco[0].uf}
                                        onChange={(e) => {
                                            const updatedEnderecos = [...selectedEscritorio.endereco];
                                            updatedEnderecos[0].uf = e.target.value;
                                            setSelectedEscritorio({
                                                ...selectedEscritorio,
                                                endereco: updatedEnderecos,
                                            });
                                        }}>
                                        <option key={''} value={''}>
                                            Selecione a UF
                                        </option>
                                        {estados.map((estado) => (
                                            <option key={estado.sigla} value={estado.sigla}>
                                                {estado.nome}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSaveEscritorio}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir o escritório <b>{selectedEscritorio.id}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteEscritorio()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Escritorios;