import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./admin/Login";
import AdminTemplate from "./admin/AdminApp";
import SiteTemplate from "./site/SiteApp";

const Content = props => (
  <main className="Content">
    <Routes>
        <Route path="/*" exact element={<SiteTemplate />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin/*" element={<AdminTemplate />} />
    </Routes>
  </main>
);
 
export default Content;