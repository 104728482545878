import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { endpointLogin, maxAgeCookieJWTUsuario, nomeJWTUsuario, urlHost } from '../../../../utils/Constants';
import logo from '../../../../resources/img/logo-dias-nogueira.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(nomeJWTUsuario);
  const navigate = useNavigate();

  function checkLoggedIn(){
    if(cookies[nomeJWTUsuario]){
      navigate('/admin');
    }else{
      removeCookie(nomeJWTUsuario, { path: '/' });
    }
  }

  const handleLogin = (event) => {
    setMsgAlert('');
    const usuario = {login: email, senha: password};
      axios.post(urlHost+endpointLogin, usuario)
        .then(response => {          
          if(response.status === 200){
            if(response.data){
              if(response.data.token!=null && response.data.token.length > 0){
                setCookie(nomeJWTUsuario, response.data.token, {maxAge: maxAgeCookieJWTUsuario, secure: false, sameSite: true});
                navigate('/admin');
              }else{
                setMsgAlert('Usuário ou senha incorretos.');
              }
            }else{
              setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
            }
          }else if(response.status === 403){
            setMsgAlert('Usuário ou senha incorretos.');
          }else{
            setMsgAlert('Ocorreu um erro ao realizar o login. Tente novamente em alguns instantes.');
          }
        })
        .catch(error => {
          if(error.response.status === 403){
            setMsgAlert('Usuário ou senha incorretos.');
          }else{
            setMsgAlert('Ocorreu um erro ao realizar o login. Tente novamente em alguns instantes.');
          }
        });
  };

  function onKeyUp(event) {
    if (event.keyCode === 13) {
      handleLogin();
    }
  }

  useEffect(() => {
    checkLoggedIn();
  });

  return (
    <Container className="d-flex align-items-center justify-content-center login-page">
      <div className="w-100 login-container">
        <div className='login-logo-container'>
          <img src={logo} height={100} alt="Logo Dias Nogueira"/>
        </div>
        
        <Form onKeyUp={onKeyUp}>
          {msgAlert.length > 0 ?
            <Alert variant='danger'>
              {msgAlert}
            </Alert>
            : null
          }
          <Form.Group controlId="formBasicUser" className='login-group'>
            <Form.Label>Usuário</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="Digite seu usuário"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className='login-group'>
            <Form.Label>Senha</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text><FontAwesomeIcon icon={faLock}/></InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
            </InputGroup>
          </Form.Group>

          <div className='login-footer'>
            <Button variant="primary" type="button" className="w-100" onClick={(e) => handleLogin(e)}>
              Entrar
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default LoginPage;